html,
body,
#root {
  height: 100%;
}

.pf-v6-c-content {
  --pf-v6-c-content--small--Color: red; /* changes all <small> color to red */
  --pf-v6-c-content--blockquote--BorderLeftColor: purple; /* changes all <blockquote> left border color to purple */
  --pf-v6-c-content--hr--BackgroundColor: lemonchiffon; /* changes a <hr> color to lemonchiffon */
}
